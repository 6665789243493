.app_container {
  background: var(--web_background_color);
  transition: 1s all ease-in;
}

@media screen and (max-width: 768px) {
  .app_container {
    padding-bottom: 100px;
  }
}
