.desktop_call_button {
  position: fixed;
  top: 20px;
  right: 5%;
  list-style: none;
  color: inherit;
  align-items: center;
  appearance: none;
  background-image: var(--call_button_background);
  border: 0;
  border-radius: var(--border_radius);
  box-shadow: rgba(45, 35, 66, 0.4) 0 2px 4px,
    rgba(45, 35, 66, 0.3) 0 7px 13px -3px, rgba(58, 65, 111, 0.5) 0 -3px 0 inset;
  box-sizing: border-box;
  color: var(--call_button_text_color);
  cursor: pointer;
  display: inline-flex;
  font-family: "JetBrains Mono", monospace;
  height: 48px;
  justify-content: center;
  line-height: 1;
  list-style: none;
  overflow: hidden;
  padding-left: 16px;
  padding-right: 16px;
  text-align: left;
  text-decoration: none;
  transition: box-shadow 0.15s, transform 0.15s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
  will-change: box-shadow, transform;
  font-size: 18px;
}

.desktop_call_button:focus {
  box-shadow: #3c4fe0 0 0 0 1.5px inset, rgba(45, 35, 66, 0.4) 0 2px 4px,
    rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #3c4fe0 0 -3px 0 inset;
}
.desktop_call_button:hover {
  box-shadow: rgba(45, 35, 66, 0.4) 0 4px 8px,
    rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #3c4fe0 0 -3px 0 inset;
  transform: translateY(-5px);
}

.desktop_call_button:active {
  box-shadow: #3c4fe0 0 3px 7px inset;
  transform: translateY(2px);
}

@media screen and (max-width: 768px) {
  .desktop_call_button {
    /* top: 20px; */
    /* right: 10%; */
    top: 90vh;
    left: 50%;
    transform: translateX(-50%);
    /* transform: translateY(-50%); */
  }
  .desktop_call_button:hover {
    transform: translateX(-50%);
  }
  
  .desktop_call_button:active {
    transform: translateX(-50%);
  }
}
