.services_container {
  width: 100%;
  height: auto;
  padding: 30px;
  background: var(--services_container_back_color);
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
}

.services_container_item_card {
  width: 270px;
  height: 320px;
  padding: 15px;
  border: 1px solid var(--services_card_border_color);
  border-radius: var(--border_radius);
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  gap: 10px;
  transition: 0.5s all ease-in;
  cursor: pointer;
}

.services_container_item_card > h2 {
  color: var(--services_card_title_color);
  font-weight: bolder;
}
.services_container_item_card > h4 {
  color: var(--services_card_details_color);
  transition: 0.5s all ease-in;
}
.services_container_item_card:hover {
  background: var(--services_card_details_color);
  transform: scale(1.05);
}
.services_container_item_card:hover > h4 {
  color: var(--services_card_title_color);
}

@media screen and (max-width: 608px) {
  .services_container_item_card {
    width: 70%;
  }
}
@media screen and (max-width: 458px) {
  .services_container_item_card {
    width: 90%;
  }
}
@media screen and (max-width: 424px) {
  .services_container_item_card {
    width: 100%;
  }
}
