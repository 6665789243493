:root {
  --web_background_color: #5680E9;
  --border_radius: 5px;
  --web_title_color: purple;
  /*  */
  --call_button_background: radial-gradient(
    100% 100% at 100% 0,
    #5adaff 0,
    #5468ff 100%
  );
  --call_button_text_color: rgb(5, 0, 73);
  /*  */
  --top_banner_back_color: #5680E9;
  --top_banner_text_color: #5AB9EA;
  --second_banner_back_color: #5680E9;
  --second_banner_text_color: #5AB9EA;
  /*  */
  --services_container_back_color: #5680E9;
  --services_card_title_color: #003b94;
  --services_card_details_color: #5AB9EA;
  --services_card_border_color: #5AB9EA;
  /*  */
  --directory_container_background_color: #003b94;
  --directory_container_text_color: #5680E9;

}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  background: #eeeeee;
  overflow-x: hidden;
  /* padding-bottom: 200px; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
